export function selected(item) {
  return {
    type: '@item/SELECTED',
    payload: { item },
  };
}

export function changeAmount(amount) {
  return {
    type: '@item/CHANGE_AMOUNT',
    payload: { amount },
  };
}

export function setComment(comment) {
  return {
    type: '@item/SET_COMMENT',
    payload: { comment },
  };
}

export function unsetComment() {
  return {
    type: '@item/UNSET_COMMENT',
  };
}
