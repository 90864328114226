import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import { comentarios, setComment } from '../../../services/api';

import { setComment as success } from '../item/actions';

import {
  commentsFailure,
  commentsSuccess,
  createFailure,
  createSuccess,
} from './actions';

export function* comments({ payload: { product } }) {
  try {
    const { produto_id: id = 0 } = product;
    const response = yield call(comentarios, id);
    const data = response || [];
    yield put(commentsSuccess(data));
    history.push('/comments');
  } catch (err) {
    yield put(commentsFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* create({ payload: { comment } }) {
  try {
    const response = yield call(setComment, comment);
    if (response && response.success) {
      const { data } = response;
      yield put(createSuccess(data));
      yield put(success(data));
    } else if (response && !response.success) {
      yield put(createFailure());
      toast.error(response.message);
    } else {
      yield put(createFailure());
      toast.error('Houve um erro inesperado, Tente novamente');
    }
  } catch (err) {
    yield put(createFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

export default all([
  takeLatest('@comment/COMMENTS_REQUEST', comments),
  takeLatest('@comment/CREATE_REQUEST', create),
]);
