import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import { login } from '../../../services/api';

import { signSuccess, signFailure, starting } from './actions';

export function* signIn({ payload }) {
  try {
    const { id, password } = payload;
    const response = yield call(login, { id, password });
    const user = response;
    if (user) {
      yield put(signSuccess(user));
      history.push('/');
    } else {
      yield put(signFailure());
      toast.error('Falha, id ou senha incorretos');
    }
  } catch (err) {
    yield put(signFailure());
    toast.error('Houve um erro inesperado. Tente novamente');
  }
}

export function signOut() {
  localStorage.removeItem('categorias');
  localStorage.removeItem('produtos');
  history.push('/');
}

export function* start() {
  yield put(starting());
}

export default all([
  takeLatest('@auth/SIGN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('persist/REHYDRATE', start),
]);
