import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  product: null,
  comments: [],
  comment: null,
};

export default function comment(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@comment/COMMENTS_REQUEST': {
        const { product = null } = action.payload;
        if (product) {
          draft.product = product;
          draft.loading = true;
        }
        break;
      }

      case '@comment/COMMENTS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@comment/COMMENTS_SUCCESS': {
        const { comments = [] } = action.payload;
        draft.comments = [...comments];
        draft.comment = null;
        draft.loading = false;
        break;
      }

      case '@comment/CREATE': {
        const { product = null } = action.payload;

        if (product) {
          const { produto_id: id } = product;
          draft.comment = { product_id: id, name: '' };
        }
        break;
      }

      case '@comment/CREATE_CANCEL': {
        draft.comment = null;
        break;
      }

      case '@comment/CREATE_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@comment/CREATE_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@comment/CREATE_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@starting': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
