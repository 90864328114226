import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

import { categoriesRequest } from '../../../store/modules/data/actions';

function Footer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <button
        type="button"
        className={classes.add}
        onClick={() => dispatch(categoriesRequest())}
      >
        pedir
      </button>
    </div>
  );
}

export default Footer;
