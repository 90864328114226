export function commentsRequest(product) {
  return {
    type: '@comment/COMMENTS_REQUEST',
    payload: { product },
  };
}

export function commentsFailure() {
  return {
    type: '@comment/COMMENTS_FAILURE',
  };
}

export function commentsSuccess(comments) {
  return {
    type: '@comment/COMMENTS_SUCCESS',
    payload: { comments },
  };
}

export function create(product) {
  return {
    type: '@comment/CREATE',
    payload: { product },
  };
}

export function createCancel() {
  return {
    type: '@comment/CREATE_CANCEL',
  };
}

export function createRequest(comment) {
  return {
    type: '@comment/CREATE_REQUEST',
    payload: { comment },
  };
}

export function createFailure() {
  return {
    type: '@comment/CREATE_FAILURE',
  };
}

export function createSuccess(comment) {
  return {
    type: '@comment/CREATE_SUCCESS',
    payload: { comment },
  };
}
