import { combineReducers } from 'redux';

import auth from './auth/reducer';
import data from './data/reducer';
import item from './item/reducer';
import comment from './comment/reducer';
import order from './order/reducer';

export default combineReducers({
  auth,
  data,
  item,
  comment,
  order,
});
