import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  order: { items: [] },
};

export default function order(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@order/ADD_ITEM': {
        const { item: currentItem = null } = action.payload;
        if (currentItem) {
          const deprecatedItem = draft.order.items.find(
            item => item.key === currentItem.key
          );

          if (deprecatedItem) {
            const items = draft.order.items.map(item => {
              if (item.key === deprecatedItem.key) return currentItem;

              return item;
            });

            draft.order.items = [...items];
            break;
          }

          draft.order.items = [...draft.order.items, currentItem];
          break;
        }
        break;
      }

      case '@order/REMOVE_ITEM': {
        const { key = '' } = action.payload;
        const items = draft.order.items.filter(item => item.key !== key);

        draft.order.items = [...items];
        break;
      }

      case '@order/ORDER_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@order/ORDER_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@order/ORDER_SUCCESS': {
        draft.order = { items: [] };
        draft.loading = false;
        break;
      }

      case '@starting': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
