import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import data from './data/sagas';
import item from './item/sagas';
import comment from './comment/sagas';
import order from './order/sagas';

export default function* rootSaga() {
  return yield all([auth, data, item, comment, order]);
}
